const now = new Date().getTime();
const expirationTime = localStorage.getItem("expiration");
if (expirationTime < now) {
  // localStorage.clear();
  localStorage.removeItem("expiration");
  localStorage.removeItem("user");
}
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

// const initialState = {
//   isLoggedIn: true,
//   user: {
//     id: 11,
//     email: "developpeur@life-ong.org",
//     first_name: "Maroine",
//   },
// };
console.log("user???", user)
const LoggedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "IS_SIGNED_IN":
      return state;

    case "SIGN_IN":
      localStorage.setItem("user", JSON.stringify(payload));
      const hours = 40;
      const expirationTime = hours * 60 * 60 * 1000;
      localStorage.setItem("expiration", new Date().getTime() + expirationTime);
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case "SIGN_OUT":
      localStorage.removeItem("expiration");
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default LoggedReducer;
