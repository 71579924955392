import "../main.css";
import { useState } from "react";
// redux
import { loggIn } from "../../../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";
/* import logoAgil from "../../../public/logoAgil.png";
 */
import API from "../../../utils/API";
import Loading from "../../../components/Loading";
const ResetPw = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  if (isLoggedIn) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
   

    if (!email) {
      alert("You must enter your email and your password");
      return false;
    }

    setIsLoading(true);
    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL_RESET}/reset-password`,
        {
            email: email,
          
        }
      );

      // return false;
      if (response.code === 200) {
        alert("If you have an account with the given Email, you will receive an Email having a new password");
      } else if (response.code === 401) {
        //console.log("error", response);
        alert("Invalid Email");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Email");
    }

    setIsLoading(false);
  };

  return (
    <div className="donate__loginPage__container">
      <Loading open={isLoading} />
      <div className="donate__login__paper__container">
        <div align="center" className="image__conatiner">
          <img src="logoAgil.png" alt="agil"/>
        </div>
        <Paper className="donate__login__paper">
          <h1 align="center">Reset Password</h1>
          <form
            noValidate
            autoComplete="on"
            onSubmit={handleLoginSubmit}
            className="donate__login__form"
          >
            <TextField
              name="email"
              className="donate__login__el"
              type="email"
              label="Email"
            />
         
            <FormControl>
              <Button variant="contained" color="primary" type="submit">
                Reset
              </Button>
            </FormControl>
          </form>
          <h4 style={{cursor: "pointer"}} onClick={(e) => { e.preventDefault(); history.push("/login"); }}> Go to Login </h4>
        </Paper>
      </div>
    </div>
  );
};

export default ResetPw;
