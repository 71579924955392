import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import NotFound from "../layouts/404";

import { Route } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import ResetPw from "../views/Login/LostPassword";
import Settings from "../views/Settings";
import Profile from "../views/Profile";
import ChangePassword from "../views/Profile/ChangePassword";
import Frequence from "../views/Frequence";
import Responsible from "../views/Responsible";
import Category from "../views/Category";
import Controle from "../views/Controle";
import AddFrequence from "../views/Frequence/AddFrequence";
import EditFrequence from "../views/Frequence/EditFrequence";
import AddCategory from "../views/Category/AddCategory";
import AddControle from "../views/Controle/AddControle";
import AddResponsible from "../views/Responsible/AddResponible";
import EditResponsible from "../views/Responsible/EditResponsible";
import EditCategory from "../views/Category/EditCategory";
import EditControle from "../views/Controle/EditControle";
import Concerne from "../views/Concerne";
import AddConcerne from "../views/Concerne/AddConcerne";
import EditConcerne from "../views/Concerne/EditConcerne";
import Imprime from "../views/Imprime";
import AddImprime from "../views/Imprime/AddImprime";
import EditImprime from "../views/Imprime/EditImprime";
import Evenement from "../views/Evenement";
import AddEvenement from "../views/Evenement/AddEvenement";
import Location from "../views/Location";
import AddLocation from "../views/Location/AddLocation";
import EditLocation from "../views/Location/EditLocation";
import EditEvenement from "../views/Evenement/EditEvenement";
import Emplacement from "../views/Emplacement";
import AddEmplacement from "../views/Emplacement/AddEmplacement";
import EditEmplacement from "../views/Emplacement/EditEmplacement";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={DefaultLayout}
        path="/"
      />
      <RouteWithLayout
        component={Frequence}
        exact
        layout={DefaultLayout}
        path="/frequences"
      />
      <RouteWithLayout
        component={AddFrequence}
        exact
        layout={DefaultLayout}
        path="/frequence/new"
      />
      <RouteWithLayout
        component={EditFrequence}
        exact
        layout={DefaultLayout}
        path="/frequence/:id/update"
      />

      <RouteWithLayout
        component={Responsible}
        exact
        layout={DefaultLayout}
        path="/responsible"
      />
      <RouteWithLayout
        component={AddResponsible}
        exact
        layout={DefaultLayout}
        path="/responsible/new"
      />
      <RouteWithLayout
        component={EditResponsible}
        exact
        layout={DefaultLayout}
        path="/responsible/:id/update"
      />
      <RouteWithLayout
        component={Category}
        exact
        layout={DefaultLayout}
        path="/category"
      />
      <RouteWithLayout
        component={AddCategory}
        exact
        layout={DefaultLayout}
        path="/category/new"
      />
      <RouteWithLayout
        component={EditCategory}
        exact
        layout={DefaultLayout}
        path="/category/:id/update"
      />
      <RouteWithLayout
        component={Controle}
        exact
        layout={DefaultLayout}
        path="/controle"
      />
      <RouteWithLayout
        component={AddControle}
        exact
        layout={DefaultLayout}
        path="/controle/new"
      />
      <RouteWithLayout
        component={EditControle}
        exact
        layout={DefaultLayout}
        path="/controle/:id/update"
      />
      <RouteWithLayout
        component={Concerne}
        exact
        layout={DefaultLayout}
        path="/concerne"
      />
      <RouteWithLayout
        component={AddConcerne}
        exact
        layout={DefaultLayout}
        path="/concerne/new"
      />
      <RouteWithLayout
        component={EditConcerne}
        exact
        layout={DefaultLayout}
        path="/concerne/:id/update"
      />
      <RouteWithLayout
        component={Imprime}
        exact
        layout={DefaultLayout}
        path="/imprime"
      />
      <RouteWithLayout
        component={AddImprime}
        exact
        layout={DefaultLayout}
        path="/imprime/new"
      />
      <RouteWithLayout
        component={EditImprime}
        exact
        layout={DefaultLayout}
        path="/imprime/:id/update"
      />
      <RouteWithLayout
        component={Location}
        exact
        layout={DefaultLayout}
        path="/location"
      />
      <RouteWithLayout
        component={AddLocation}
        exact
        layout={DefaultLayout}
        path="/location/new"
      />
      <RouteWithLayout
        component={EditLocation}
        exact
        layout={DefaultLayout}
        path="/location/:id/update"
      />
      <RouteWithLayout
        component={Evenement}
        exact
        layout={DefaultLayout}
        path="/evenement"
      />
      <RouteWithLayout
        component={AddEvenement}
        exact
        layout={DefaultLayout}
        path="/evenement/new"
      />
      <RouteWithLayout
        component={EditEvenement}
        exact
        layout={DefaultLayout}
        path="/evenement/:id/update"
      />
      <RouteWithLayout
        component={Emplacement}
        exact
        layout={DefaultLayout}
        path="/emplacement"
      />
      <RouteWithLayout
        component={AddEmplacement}
        exact
        layout={DefaultLayout}
        path="/emplacement/new"
      />
      <RouteWithLayout
        component={EditEmplacement}
        exact
        layout={DefaultLayout}
        path="/emplacement/:id/update"
      />
      <RouteWithLayout
        component={Settings}
        layout={DefaultLayout}
        exact
        path="/settings"
      />
      <RouteWithLayout
        component={Profile}
        layout={DefaultLayout}
        exact
        path="/profile"
      />
        <RouteWithLayout
        component={ChangePassword}
        layout={DefaultLayout}
        exact
        path="/change-password"
      />
      <Route component={NotFound} exact path="/not-found" />
      <Route component={Login} exact path="/login" />
      <Route component={ResetPw} exact path="/reset-password" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
