import ChangePassword from "../Profile/ChangePassword"
const Profile = () => {
  return (
    <>
      <h1>Profile</h1>
      <ChangePassword/>
    </>
  );
};

export default Profile;
