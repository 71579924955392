import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../utils/API";
import { Button } from "@material-ui/core";
import Table from "../../components/Table";
const Responsible = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/responsibles`
        );
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Link to="/responsible/new">
        <Button
          color="primary"
          style={{
            border: "1px solid",
            width: "200px",
            position: "absolute",
            right: "10px",
          }}
        >
          Créer Responsible
        </Button>
      </Link>
      <div style={{ marginTop: "50px" }}>
        <Table
          data={data}
          actions={["edit", "delete"]}
          apiUrl={`${process.env.REACT_APP_API_BASE_URL}/responsibles`}
          title="Responsables"
          redirectUri="/responsible/"
        />
      </div>
    </>
  );
};

export default Responsible;
