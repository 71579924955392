import React, { forwardRef, useState, useEffect } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import API from "../../utils/API";
import Loading from "../../components/Loading";

const TableHistory = (props) => {
  const { data, title, actions, apiUrl, redirectUri, refreshCallback } = props;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [newActions, setNewActions] = useState([]);
  const [tableData, setTableData] = useState([]);

  const handleClick = async (event, rowData) => {
    // Do save operation
    // setColor("green");
    console.log(rowData);

    try {
      setIsLoading(true);
      const response = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}${apiUrl}${rowData.id}`
      );
      //set isValidated to true

      // console.log(response);
      if (response.code === 200) {
        refreshCallback();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleDeleteClick = async (event, rowData) => {
    try {
      setIsLoading(true);
      const response = await API(
        "DELETE",
        `${process.env.REACT_APP_API_BASE_URL}${apiUrl}${rowData.id}`
      );
      //set isValidated to true

      // console.log(response);
      if (response.code === 200) {
        setIsLoading(false);
        refreshCallback();
        alert(response.msg);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Loading open={isLoading} />
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "Imprimé", field: "event.imprime.name" },
            { title: "Désignation", field: "event.name" },
            { title: "Date", field: "createdAt.date", type: "date" },
            { title: "Fréquence", field: "event.frequence.name" },
            { title: "installations", field: "event.category.name" },
            { title: "Controles", field: "event.controle.name" },
            { title: "Concernés", field: "event.concerne.name" },
            { title: "Emplacement", field: "event.emplacement.name" },
            { title: "Responsables", field: "event.responsible.name" },
          ]}
          data={data}
          title={title}
          actions={[
            (rowData) => ({
              icon: forwardRef((props, ref) =>
                rowData.status ? (
                  <HighlightOffIcon
                    style={{
                      fontSize: "25px",
                      color: "red",
                    }}
                  />
                ) : (
                  <CheckCircleOutlineOutlinedIcon
                    style={{
                      fontSize: "25px",
                      color: "green",
                    }}
                    {...props}
                    ref={ref}
                  />
                )
              ),
              tooltip: rowData.status ? "Invalider" : "Valider",
              onClick: handleClick,
            }),
            (rowData) => ({
              icon: forwardRef((props, ref) => <DeleteOutline />),
              tooltip: "Supprimer",
              onClick: handleDeleteClick,
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 50,
            pageSizeOptions: [5, 10, 20, 50],
          }}
        />
      </div>
    </div>
  );
};

export default TableHistory;
