import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const RouteWithLayout = (props) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);
  // const isLoggedIn = true;

  const { layout: Layout, component: Component, ...rest } = props;
  return isLoggedIn ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  ) : (
    <Redirect to="/login" />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
