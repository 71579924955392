import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../utils/API";
import { Button } from "@material-ui/core";
import TableEvenement from "../../components/TableEvenemet";

const Evenement = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/evenements/user`
        );
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Link to="/evenement/new">
        <Button
          color="primary"
          style={{
            border: "1px solid",
            width: "200px",
            position: "absolute",
            right: "10px",
          }}
        >
          Créer Evenement
        </Button>
      </Link>
      <div style={{ marginTop: "50px" }}>
        <TableEvenement
          data={data}
          actions={["edit", "delete"]}
          apiUrl={`${process.env.REACT_APP_API_BASE_URL}/evenements`}
          title="Evenements"
          redirectUri="/evenement/"
        />
      </div>
    </>
  );
};

export default Evenement;
