import React, { forwardRef, useState, useEffect } from "react";
import MaterialTable from "material-table";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import API from "../../utils/API";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";

const TableEvenement = (props) => {
  const { data, title, actions, apiUrl, redirectUri } = props;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const history = useHistory();

  const [newActions, setNewActions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTableData(data);

    let newActionArray = [];

    actions.map((action, index) => {
      if (action === "delete") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <DeleteOutlineIcon
              style={{ fontSize: "14px" }}
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Supprimer",
          onClick: async (event, rowData) => {
            // Do save operation
            let res = window.confirm(
              "Vous voulez vraiment supprimer cette enregistrement?"
            );
            if (res) {
              try {
                setIsLoading(true);
                const resp = await API("DELETE", `${apiUrl}/${rowData.id}`);
                setTableData(tableData.splice(rowData.tableData.id, 1));
                setIsLoading(false);
                alert(resp.msg);
              } catch (error) {
                console.error(error);
                setIsLoading(false);
              }
            }
          },
        });

        return action;
      } else if (action === "edit") {
        newActionArray.push({
          icon: forwardRef((props, ref) => (
            <EditOutlinedIcon
              style={{ fontSize: "14px" }}
              {...props}
              ref={ref}
            />
          )),
          tooltip: "Modifier",
          onClick: (event, rowData) => {
            // Do save operation

            history.push(redirectUri + rowData.id + "/update");
          },
        });
      }
    });

    setNewActions(newActionArray);

    return () => {
      setNewActions([]);
    };
  }, [actions, apiUrl, data, tableData, apiUrl, history, redirectUri]);

  return (
    <div>
      <Loading open={isLoading} />
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          icons={tableIcons}
          columns={[
            { title: "Installation ", field: "category.name" },
            { title: "Lieu", field: "location.name" },
            { title: "Désignation", field: "name" },
            { title: "Imprimé", field: "imprime.name" },
            { title: "Emplacement", field: "emplacement.name" },
            { title: "Fréquence", field: "frequence.name" },
            { title: "Résponsable", field: "responsible.name" },
            { title: "Concernées", field: "concerne.name" },
            { title: "Controlé par", field: "controle.name" },
          ]}
          data={tableData}
          title={title}
          actions={newActions}
          options={{
            actionsColumnIndex: -1,
            pageSize: 200,
            pageSizeOptions: [5, 10, 20, 50],
          }}
        />
      </div>
    </div>
  );
};

export default TableEvenement;
