import { useState, useEffect } from "react";

import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, Paper, TextField } from "@material-ui/core";

const EditResponsible = () => {
  const { id } = useParams();
  const [LoadingOpen, setLoadingOpen] = useState(false);
  const [data, setData] = useState([]);

  const history = useHistory();
  useEffect(() => {
    const fecthData = async (e) => {
      const res = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/responsibles/${id}`
      );
      setData(res.data);
    };
    fecthData();
    return () => {
      setData([]);
    };
  }, [id]);

  const handleNameChange = (e) => {
    // console.log(e.target.value);
    setData({ ...data, name: e.target.value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;

    if (!name) {
      alert("Il faut remplir le champ");
      return false;
    }

    try {
      setLoadingOpen(true);
      // save data api here

      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/responsibles/${id}`,
        { name }
      );

      if (res.code === 200) {
        alert("Responsable modifié avec succés");
        history.push("/responsible");
      } else {
        alert(
          "Une erreur est survenue, Veuillez réessayer, si l'erreur persiste contacter l'administrateur du site."
        );
      }
      setLoadingOpen(false);
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading open={LoadingOpen} />
      <Grid justify="center" alignItems="center" container spacing={3}>
        <Grid item xs={6}>
          <h2>Modifier Responsable </h2>
          <Paper
            style={{
              padding: "20px",
            }}
          >
            <form onSubmit={onSubmitHandler}>
              <div align="center" style={{ marginBottom: "20px" }}>
                <TextField
                  id="standard-basic"
                  label="Name"
                  name="name"
                  value={data.name ?? ""}
                  onChange={handleNameChange}
                  fullWidth
                />
              </div>
              <div align="center">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ color: "black" }}
                >
                  Mettre à jour
                </Button>{" "}
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Annuler
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditResponsible;
