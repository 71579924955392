import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../utils/API";
import { Button } from "@material-ui/core";
import Table from "../../components/Table";

const Frequence = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/frequences`
        );
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Link to="/frequence/new">
        <div>
          <Button
            color="primary"
            style={{
              border: "1px solid",
              width: "200px",
              position: "absolute",
              right: "10px",
            }}
          >
            Créer Frequence
          </Button>
        </div>
      </Link>
      <div style={{ marginTop: "50px" }}>
        <Table
          data={data}
          actions={["edit", "delete"]}
          apiUrl={`${process.env.REACT_APP_API_BASE_URL}/frequences`}
          title="Frequence"
          redirectUri="/frequence/"
        />
      </div>
    </>
  );
};

export default Frequence;
