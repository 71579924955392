import { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const EditEvenement = () => {
  const { id } = useParams();
  const [LoadingOpen, setLoadingOpen] = useState(false);
  const [data, setData] = useState([]);

  const history = useHistory();

  const [frequence, setFrequence] = useState([]);

  const [selectedFrequence, setSelectedFrequence] = useState("");

  const oneforallChange = (el, index) => {
    if (index === "frequence") {
      setData({ ...data, frequence: el.target.value });
      setSelectedFrequence(el.target.value);
    } else if (index === "location") {
      setData({ ...data, location: el.target.value });
    } else if (index === "emplacement") {
      setData({ ...data, emplacement: el.target.value });
    } else if (index === "name") {
      setData({ ...data, name: el.target.value });
    } else if (index === "imprime") {
      setData({ ...data, imprime: el.target.value });
    } else if (index === "category") {
      setData({ ...data, category: el.target.value });
    } else if (index === "concerne") {
      setData({ ...data, concerne: el.target.value });
    } else if (index === "controle") {
      setData({ ...data, controle: el.target.value });
    } else if (index === "responsible") {
      setData({ ...data, responsible: el.target.value });
    } else if (index === "jour") {
      setData({ ...data, jour: el.target.value });
    } else if (index === "semaine") {
      setData({ ...data, semaine: el.target.value });
    } else if (index === "date") {
      setData({ ...data, date: el.target.value });
    }
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
  };

  const [imprime, setImprime] = useState([]);
  const [category, setCategory] = useState([]);
  const [concerne, setConcerne] = useState([]);
  const [controle, setControle] = useState([]);
  const [responsible, setResponsible] = useState([]);
  const [location, setLocation] = useState([]);
  const [emplacement, setEmplacement] = useState([]);

  useEffect(() => {
    const initData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/evenements/init-event`
        );
        setFrequence(res.data.frequences);
        setImprime(res.data.imprimes);
        setCategory(res.data.categories);
        setConcerne(res.data.concernes);
        setControle(res.data.controles);
        setResponsible(res.data.responsibles);
        setLocation(res.data.locations);
        setEmplacement(res.data.emplacements);
      } catch (error) {
        console.log(error);
      }
    };
    initData();

    const fecthData = async (e) => {
      const res = await API(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/evenements/${id}`
      );

      setData({
        name: res.data.name,
        location: res.data.location ? res.data.location.id : "",
        emplacement: res.data.emplacement ? res.data.emplacement.id : "",
        frequence: res.data.frequence ? res.data.frequence.id : "",
        imprime: res.data.imprime ? res.data.imprime.id : "",
        category: res.data.category ? res.data.category.id : "",
        concerne: res.data.concerne ? res.data.concerne.id : "",
        controle: res.data.controle ? res.data.controle.id : "",
        jour: res.data.jour ? res.data.jour : "",
        semaine: res.data.semaine ? res.data.semaine : "",
        responsible: res.data.responsible
          ? res.data.responsible.id.toString()
          : "",
        date: res.data.date ? res.data.date.date : "",
      });
      setSelectedFrequence(res.data.frequence ? res.data.frequence.id : "");
      setSelectedDate(res.data.date ? res.data.date.date : "");
    };
    fecthData();
  }, [id]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const frequence = e.target.frequence.value;
    const imprime = e.target.imprime.value;
    const concerne = e.target.concerne.value;
    const category = e.target.category.value;
    const controle = e.target.controle.value;
    const responsible = e.target.responsible.value;
    const location = e.target.location.value;
    const emplacement = e.target.emplacement.value;
    const jour = e.target.jour ? e.target.jour.value : "";
    const semaine = e.target.semaine ? e.target.semaine.value : "";

    var dateEdited =
      new Date(selectedDate).getFullYear() +
      "/" +
      (new Date(selectedDate).getMonth() + 1) +
      "/" +
      new Date(selectedDate).getDate();
    if (
      frequence !== "8" &&
      frequence !== "4" &&
      frequence !== "7" &&
      frequence !== "6" &&
      frequence !== "9" &&
      frequence !== "10" &&
      frequence !== "11" &&
      frequence !== "13"
    ) {
      dateEdited = "";
    }

    if (!name) {
      alert("il faut remplir le champ");
      return false;
    }
    try {
      setLoadingOpen(true);
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/evenements/${id}`,
        {
          name,
          frequence,
          imprime,
          concerne,
          category,
          controle,
          responsible,
          location,
          emplacement,
          jour,
          semaine,
          date: dateEdited,
        }
      );
      console.log(res);
      if (res.code === 200) {
        setLoadingOpen(false);
        alert("Evenement modifié avec succés");
        history.push("/evenement");
      } else {
        setLoadingOpen(false);
        alert(
          "Une erreur est survenue, Veuillez réessayer, si l'erreur persiste contacter l'administrateur du site."
        );
      }
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading open={LoadingOpen} />
      <Grid justify="center" alignItems="center" container spacing={3}>
        <Grid item xs={6}>
          <h2>Modifier Evenement </h2>
          <Paper
            style={{
              padding: "20px",
            }}
          >
            <form onSubmit={onSubmitHandler}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <TextField
                    id="standard-basic"
                    label="Nom"
                    name="name"
                    value={data.name ?? ""}
                    onChange={(e) => {
                      oneforallChange(e, "name");
                    }}
                  />
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="location_label">Lieu</InputLabel>
                  <Select
                    labelId="location_label"
                    id="locationId"
                    name="location"
                    value={data.location ? data.location : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "location");
                    }}
                  >
                    {location.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="frequence_label">Frequence</InputLabel>
                  <Select
                    onChange={(e) => {
                      oneforallChange(e, "frequence");
                    }}
                    labelId="frequence_label"
                    id="frequenceId"
                    name="frequence"
                    value={data.frequence ? data.frequence : ""}
                    fullWidth
                  >
                    {frequence.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="imprime_label">Imprime</InputLabel>
                  <Select
                    labelId="imprime_label"
                    id="imprimeId"
                    name="imprime"
                    value={data.imprime ? data.imprime : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "imprime");
                    }}
                  >
                    {imprime.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="category_label">Catégorie</InputLabel>
                  <Select
                    labelId="category_label"
                    id="categoryId"
                    name="category"
                    value={data.category ? data.category : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "category");
                    }}
                  >
                    {category.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="concerne_label">Concerné</InputLabel>
                  <Select
                    labelId="concerne_label"
                    id="concerneId"
                    name="concerne"
                    value={data.concerne ? data.concerne : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "concerne");
                    }}
                  >
                    {concerne.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="controle_label">Controle</InputLabel>
                  <Select
                    labelId="controle_label"
                    id="controleId"
                    name="controle"
                    value={data.controle ? data.controle : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "controle");
                    }}
                  >
                    {controle.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="responsible_label">Responsable</InputLabel>
                  <Select
                    labelId="responsible_label"
                    id="responsibleId"
                    name="responsible"
                    value={data.responsible ? data.responsible : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "responsible");
                    }}
                  >
                    {responsible.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="emplacement_label">Emplacement</InputLabel>
                  <Select
                    labelId="emplacement_label"
                    id="emplacementId"
                    name="emplacement"
                    value={data.emplacement ? data.emplacement : ""}
                    fullWidth
                    onChange={(e) => {
                      oneforallChange(e, "emplacement");
                    }}
                  >
                    {emplacement.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedFrequence === 2 ? null : selectedFrequence === 1 ? (
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <InputLabel id="jour_label">Jour</InputLabel>
                    <Select
                      labelId="jour_label"
                      id="jourId"
                      name="jour"
                      value={data.jour ? data.jour : ""}
                      fullWidth
                      onChange={(e) => {
                        oneforallChange(e, "jour");
                      }}
                    >
                      <MenuItem value="lundi">lundi</MenuItem>
                      <MenuItem value="mardi">mardi</MenuItem>
                      <MenuItem value="mercredi">mercredi</MenuItem>
                      <MenuItem value="jeudi">jeudi</MenuItem>
                      <MenuItem value="vendredi">vendredi</MenuItem>
                      <MenuItem value="samedi">samedi</MenuItem>
                      <MenuItem value="dimanche">dimanche</MenuItem>
                    </Select>
                  </FormControl>
                ) : selectedFrequence === 3 ? (
                  <>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <InputLabel id="jour_label">Jour</InputLabel>
                      <Select
                        labelId="jour_label"
                        id="jourId"
                        name="jour"
                        value={data.jour ? data.jour : ""}
                        fullWidth
                        onChange={(e) => {
                          oneforallChange(e, "jour");
                        }}
                      >
                        <MenuItem value="lundi">lundi</MenuItem>
                        <MenuItem value="mardi">mardi</MenuItem>
                        <MenuItem value="mercredi">mercredi</MenuItem>
                        <MenuItem value="jeudi">jeudi</MenuItem>
                        <MenuItem value="vendredi">vendredi</MenuItem>
                        <MenuItem value="samedi">samedi</MenuItem>
                        <MenuItem value="dimanche">dimanche</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <InputLabel id="semaine_label">Semaine</InputLabel>
                      <Select
                        labelId="semaine_label"
                        id="semaineId"
                        fullWidth
                        name="semaine"
                        value={data.semaine ? data.semaine : ""}
                        fullWidth
                        onChange={(e) => {
                          oneforallChange(e, "semaine");
                        }}
                      >
                        <MenuItem value={1}>1ere semaine</MenuItem>
                        <MenuItem value={2}>2eme semaine</MenuItem>
                        <MenuItem value={3}>3eme semaine</MenuItem>
                        <MenuItem value={4}>4eme semaine</MenuItem>
                        <MenuItem value={5}>5eme semaine</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <div style={{ marginBottom: "20px" }}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Date du prochain évenement"
                      fullWidth
                      format="dd/MM/yyyy"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      value={selectedDate}
                      onChange={handleSelectedDateChange}
                    />
                  </div>
                )}
                <div align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ color: "black" }}
                  >
                    Mettre à jour
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EditEvenement;
