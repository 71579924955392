// import "./main.css";
import { useState } from "react";
// redux
import { loggIn } from "../../../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";
/* import logoAgil from "../../../public/logoAgil.png";
 */
import API from "../../../utils/API";
import Loading from "../../../components/Loading";
const ChangePassword = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

console.log("isLoggedIn??", isLoggedIn);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    
    const pass = event.target.password.value;
    const passConf = event.target.passwordConfirmation.value;

    if (!passConf || !pass) {
      alert("You must enter your password and password confirmation");
      return false;
    }
    if (passConf !== pass) {
        alert("Password and password confirmation are not the same");
        return false;
      }
    setIsLoading(true);
    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/update-password`,
        {
      
          password: pass,
        }
      );

      // return false;
      if (response.code === 200) {
        //console.log("error", response);
        alert("Mot de passe mis à jours avec succés!");
      
      } else if (response.code === 401) {
        //console.log("error", response);
        alert("Invalid Email or Password");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Error, contact administrator");
    }

    setIsLoading(false);
  };

  return (
    <div >
      <Loading open={isLoading} />
      <div>
    
        <Paper className="donate__login__paper">
          <h1 align="center">Change Password</h1>
          <form
            noValidate
            autoComplete="on"
            onSubmit={handleLoginSubmit}
            className="donate__login__form"
          >
            <TextField
              name="password"
              className="donate__login__el"
              type="password"
              label="New Password"
            />
            <TextField
              name="passwordConfirmation"
              className="donate__login__el"
              type="password"
              label="New Password Confirmation"
            />
            <FormControl>
              <Button variant="contained" color="primary" type="submit">
                Change Password
              </Button>
            </FormControl>
          </form>
        
        </Paper>
      </div>
    </div>
  );
};

export default ChangePassword;
