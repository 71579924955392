const Settings = () => {
  return (
    <>
      <h1>Settings</h1>
      <p>liste des administrateurs + gestions</p>
    </>
  );
};

export default Settings;
