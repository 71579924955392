const NotFound = () => {
  return (
    <div style={{ width: "100%", height: "100vh", display: "flex" }}>
      <div style={{ margin: "auto", textAlign: "center" }}>
        <h1>404 NOT FOUND</h1>
        <a
          href="https://life-ong.org"
          style={{ textDecoration: "none", color: "#1976d2" }}
        >
          {" "}
          {"<<"} Go home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
