import { useState } from "react";

import Loading from "../../../components/Loading";
import API from "../../../utils/API";
import { useHistory } from "react-router-dom";
import { Button, Grid, Paper, TextField } from "@material-ui/core";

const AddCategory = () => {
  const [LoadingOpen, setLoadingOpen] = useState(false);

  const history = useHistory();
  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const name = e.target.name.value;

    if (!name) {
      alert("Il faut remplir le champ");
      return false;
    }

    try {
      setLoadingOpen(true);
      // save data api here
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/categories`,
        { name }
      );
      console.log(res);
      if (res.code === 200) {
        alert("Catégorie ajouté avec succées");
        history.push("/category");
      } else {
        alert(
          "Une erreur est survenue, Veuillez réessayer, si l'erreur persiste contacter l'administrateur du site."
        );
      }
      setLoadingOpen(false);
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading open={LoadingOpen} />
      <Grid justify="center" alignItems="center" container spacing={3}>
        <Grid item xs={6}>
          <h2>Ajouter Installation </h2>
          <Paper
            style={{
              padding: "20px",
            }}
          >
            <form onSubmit={onSubmitHandler}>
              <div align="center" style={{ marginBottom: "20px" }}>
                <TextField
                  id="standard-basic"
                  label="Nom"
                  name="name"
                  fullWidth
                />
              </div>
              <div align="center">
                <Button variant="contained" color="primary" type="submit">
                  Ajouter
                </Button>{" "}
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Annuler
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddCategory;
