import { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import API from "../../../utils/API";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
const AddEvenement = () => {
  const [LoadingOpen, setLoadingOpen] = useState(false);
  const history = useHistory();

  const [frequence, setFrequence] = useState([]);

  const [selectedFrequence, setSelectedFrequence] = useState("");
  const onFrequenceChange = (el) => {
    setSelectedFrequence(el.target.value);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
  };

  const [imprime, setImprime] = useState([]);
  const [category, setCategory] = useState([]);
  const [concerne, setConcerne] = useState([]);
  const [controle, setControle] = useState([]);
  const [responsible, setResponsible] = useState([]);
  const [location, setLocation] = useState([]);
  const [emplacement, setEmplacement] = useState([]);
  const [jour, setJour] = useState("");
  const [semaine, setSemaine] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/evenements/init-event`
        );
        setFrequence(res.data.frequences);
        setImprime(res.data.imprimes);
        setCategory(res.data.categories);
        setConcerne(res.data.concernes);
        setControle(res.data.controles);
        setResponsible(res.data.responsibles);
        setLocation(res.data.locations);
        setEmplacement(res.data.emplacements);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  const onChangeSelect = (e) => {
    setJour(e.target.value);
  };

  const onSemaineChangeSelect = (e) => {
    setSemaine(e.target.value);
  };
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const frequence = e.target.frequence.value;
    const imprime = e.target.imprime.value;
    const concerne = e.target.concerne.value;
    const category = e.target.category.value;
    const controle = e.target.controle.value;
    const responsible = e.target.responsible.value;
    const location = e.target.location.value;
    const emplacement = e.target.emplacement.value;

    const datetosave =
      frequence === 1 || frequence === 2 || frequence === 3 ? "" : selectedDate;

    if (!name) {
      alert("il faut remplir le champ");
      return false;
    }

    try {
      setLoadingOpen(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/evenements`,
        {
          name,
          frequence,
          imprime,
          concerne,
          category,
          controle,
          responsible,
          location,
          emplacement,
          jour,
          semaine,
          date: datetosave,
        }
      );
      console.log("res", res);
      if (res.code === 200) {
        setLoadingOpen(false);
        alert("Evenement ajouté avec succés");
        history.push("/evenement");
      } else if (res.code === 400) {
        alert(res.msg);
        setLoadingOpen(false);
      } else {
        setLoadingOpen(false);
        alert(
          "Une erreur est survenue, Veuillez réessayer, si l'erreur persiste contacter l'administrateur du site."
        );
      }
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };

  return (
    <>
      <Loading open={LoadingOpen} />
      <Grid justify="center" alignItems="center" container spacing={3}>
        <Grid item xs={6}>
          <h2>Ajouter Evenement </h2>
          <Paper
            style={{
              padding: "20px",
            }}
          >
            <form onSubmit={onSubmitHandler}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div style={{ marginBottom: "20px" }}>
                  <TextField
                    id="standard-basic"
                    label="Nom"
                    name="name"
                    fullWidth
                  />
                </div>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="location_label">Lieu</InputLabel>
                  <Select
                    labelId="location_label"
                    id="locationId"
                    name="location"
                    fullWidth
                  >
                    {location.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="frequence_label">Frequence</InputLabel>
                  <Select
                    onChange={onFrequenceChange}
                    value={selectedFrequence}
                    labelId="frequence_label"
                    id="frequenceId"
                    name="frequence"
                    fullWidth
                  >
                    {frequence.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="imprime_label">Imprime</InputLabel>
                  <Select
                    labelId="imprime_label"
                    id="imprimeId"
                    name="imprime"
                    fullWidth
                  >
                    {imprime.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="category_label">Catégorie</InputLabel>
                  <Select
                    labelId="category_label"
                    id="categoryId"
                    name="category"
                    fullWidth
                  >
                    {category.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="concerne_label">Concerné</InputLabel>
                  <Select
                    labelId="concerne_label"
                    id="concerneId"
                    name="concerne"
                    fullWidth
                  >
                    {concerne.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="controle_label">Controle</InputLabel>
                  <Select
                    labelId="controle_label"
                    id="controleId"
                    name="controle"
                    fullWidth
                  >
                    {controle.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="responsible_label">Responsable</InputLabel>
                  <Select
                    labelId="responsible_label"
                    id="responsibleId"
                    name="responsible"
                    fullWidth
                  >
                    {responsible.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: "20px" }}>
                  <InputLabel id="emplacement_label">Emplacement</InputLabel>
                  <Select
                    labelId="emplacement_label"
                    id="emplacementId"
                    name="emplacement"
                    fullWidth
                  >
                    {emplacement.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedFrequence === 2 ? null : selectedFrequence === 1 ? (
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <InputLabel id="jour_label">Jour</InputLabel>
                    <Select
                      labelId="jour_label"
                      id="jourId"
                      name="jour"
                      value={jour}
                      onChange={onChangeSelect}
                      fullWidth
                    >
                      <MenuItem value="lundi">lundi</MenuItem>
                      <MenuItem value="mardi">mardi</MenuItem>
                      <MenuItem value="mercredi">mercredi</MenuItem>
                      <MenuItem value="jeudi">jeudi</MenuItem>
                      <MenuItem value="vendredi">vendredi</MenuItem>
                      <MenuItem value="samedi">samedi</MenuItem>
                      <MenuItem value="dimanche">dimanche</MenuItem>
                    </Select>
                  </FormControl>
                ) : selectedFrequence === 3 ? (
                  <>
                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <InputLabel id="jour_label">Jour</InputLabel>
                      <Select
                        labelId="jour_label"
                        id="jourId"
                        name="jour"
                        value={jour}
                        onChange={onChangeSelect}
                        fullWidth
                      >
                        <MenuItem value="lundi">lundi</MenuItem>
                        <MenuItem value="mardi">mardi</MenuItem>
                        <MenuItem value="mercredi">mercredi</MenuItem>
                        <MenuItem value="jeudi">jeudi</MenuItem>
                        <MenuItem value="vendredi">vendredi</MenuItem>
                        <MenuItem value="samedi">samedi</MenuItem>
                        <MenuItem value="dimanche">dimanche</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ marginBottom: "20px" }}>
                      <InputLabel id="semaine_label">Semaine</InputLabel>
                      <Select
                        labelId="semaine_label"
                        id="semaineId"
                        fullWidth
                        name="semaine"
                        value={semaine}
                        onChange={onSemaineChangeSelect}
                      >
                        <MenuItem value="1">1ere semaine</MenuItem>
                        <MenuItem value="2">2eme semaine</MenuItem>
                        <MenuItem value="3">3eme semaine</MenuItem>
                        <MenuItem value="4">4eme semaine</MenuItem>
                        <MenuItem value="5">5eme semaine</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <div style={{ marginBottom: "20px" }}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Date"
                      fullWidth
                      format="dd/MM/yyyy"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      value={selectedDate}
                      onChange={handleSelectedDateChange}
                    />
                  </div>
                )}
                <div align="center">
                  <Button variant="contained" color="primary" type="submit">
                    Ajouter
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddEvenement;
