import { useState, useEffect } from "react";
import API from "../../utils/API";
import Button from "@material-ui/core/Button";

import TableHistory from "../../components/TableHistory";
import Loading from "../../components/Loading";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/history`
        );
        setData(res.data);
        setLoadingOpen(false);
        // console.log(res);
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();
  }, [refresh]);

  const handleRefreshCallback = () => {
    setRefresh(!refresh);
  };

  const handleValidateAllClick = async () => {
    try {
      setLoadingOpen(true);

      const response = window.confirm(
        "Êtes-vous sûr de valider tous les evenements!"
      );

      if (!response) {
        return false;
      }

      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/history/validate-all`
      );

      if (res.code === 200) {
        alert(res.msg);
        handleRefreshCallback();
        setLoadingOpen(false);
      } else {
        alert("Une erreur est survenue");
        setLoadingOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Loading open={loadingOpen} />
      <div>
        <h2 style={{ fontFamily: "Times New Roman", color: "#fed102" }}>
          Agil Air{" "}
          <span>
            {Object.keys(data).map((event, i) => {
              return i === 0 ? data[0].event.location.name : "";
            })}
          </span>
        </h2>
      </div>
      <div align="right">
        <Button
          color="primary"
          style={{
            border: "1px solid",
          }}
          onClick={handleValidateAllClick}
        >
          Valider Evenements
        </Button>
      </div>

      <div style={{ marginTop: "50px" }}>
        <TableHistory
          data={data}
          title="Événements du jour"
          redirectUri="/"
          apiUrl="/history/"
          refreshCallback={handleRefreshCallback}
        />
      </div>
    </>
  );
};

export default Dashboard;
